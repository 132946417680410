import React, { useState, useEffect } from 'react';
import './kr.scss';
import Pagination from 'react-bootstrap/Pagination';
import Collapse from 'react-bootstrap/Collapse';
import Form from 'react-bootstrap/Form';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { FiDownload } from "react-icons/fi";
import helpers from "../helpers";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import KrEdit from './KrEdit';
import Alert from 'react-bootstrap/Alert';
import { Session } from '@pnp/sp-taxonomy';
import { SPFetchClient } from "@pnp/nodejs";
import {
    taxonomy,
    ITermStore,
    ITerms,
    ILabelMatchInfo,
    ITerm,
    ITermData
} from "@pnp/sp-taxonomy";
import { debug } from 'console';
import { sp, SPRequestExecutorClient, setup } from "sp-pnp-js";
import { result } from 'lodash';
import { useSelector } from 'react-redux';
import ReactExport from "react-export-excel-fixed-xlsx";

const Kr = (params: any) => {

    interface panelItem {
        title: string,
        filePath: string,
        fileType: string,
        techFunction: string,
        discipline: string,
        pteArea: string,
        createdBy: string,
        approvedOn: string,
        validTill: string,
        primaryExpertiseArea: string,
        additionalExpertiseArea: string,
        approvedBy: string,
        fileViewPath: string,
        appInsightFilePath: string
    }


    const recordsPerPage = 30; //This will decide the pagination count.
    const [showLoading, setShowLoading] = useState(true);
    const [functionFilter, setFunctionFilter] = useState(false);
    const [disciplineFilter, setDisciplineFilter] = useState(false);
    const [pteAreaFilter, setPteAreaFilter] = useState(false);
    const [results, setResults] = useState([]);
    const [panelState, setPanelState] = useState(new Array(results.length).fill(false));
    const [functionFilters, setFunctionFilters] = useState([]);
    const [disciplineFilters, setDisciplineFilters] = useState([]);
    const [pteAreaFilters, setPteAreaFilters] = useState([]);
    const [pageCount, setPageCount] = useState(5);
    const [activePage, setaActivePage] = useState(1);
    const [documentToShow, setDocumentToShow] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [query, setQuery] = useState('');
    const [queryID, setQueryID] = useState();
    const [initalData, setInitialData] = useState<any>({});
    const [taxonomyData, setTaxonomyData] = useState<any>({});
    const [disciplineData, setDisciplineData] = useState([]);
    const [pteData, setPTEData] = useState([]);
    const [showApply, setShowApply] = useState(false);
    let tempTechFunctionQuery = [];
    let tempDisciplineQuery = [];
    let tempPteQuery = [];
    const termStore = useSelector(state => state.termStore);
    type TFile = {
        showError?: boolean,
        type?: any,
        message?: string
    }
    const [filecheck, setFileCheck] = useState({} as TFile);
    let spoaDefaultFunction = "";
    let spoaDefaultDiscipline = "";
    let spoaDefaultPTE = "";
    let tempTechFunctionVal = [];
    let tempDisciplineVal = [];
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    let currentDateTime = new Date();
    let rowLimit = 500;
    let startRow = 0;
    let allKRDocResults = [];
    let KR_Search_query = '';
    useEffect(() => {
        //localhost setup
        // taxonomy.setup({
        //   sp: {
        //     fetchClientFactory: () => {
        //       return new SPFetchClient("https://shelldevelopment.sharepoint.com/sites/SPODA0019/CSO", "a48d4dc1-a362-4632-bc11-ceb4be63131e", "D8uRZQgzS6dNkkNsjU2+pCFt3ncb7Y34Ch1L6+zsnUQ=");
        //     }
        //   },
        // });

        // getTermsetWithChildren(
        //   'https://shelldevelopment.sharepoint.com/sites/SPODA0019',
        //   //'Taxonomy_cqkQl+V9pzgHvkcONitnEA==',
        //   'Taxonomy_EERjDrgg73uLLwmXrhW92w==',
        //   '6dbfc9a3-9d84-49aa-bef7-53e188059280'
        // ).then(data => {
        //   console.log('done')
        // }).catch(e => {
        //   console.error('Fetching terms failed!')
        //   console.error(e)
        // });

        //   window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Title eq 'KR Listview'").get().then((item: any) => {
        //     setQueryID(item[0].ID);
        //     setQuery(item[0].Query);
        // }).catch(e => {
        //   console.error('Fetching query failed!')
        // });


        //PHA Setup
        //window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Title eq 'KR Listview'").get().then((item: any) => {
        //    setQueryID(item[0].ID);
        //    let TechFunctionVal = item[0].TechFunction;
        //    if (TechFunctionVal !== null) {
        //        tempTechFunctionVal = TechFunctionVal.split(',');
        //    }
        //    let DisciplineVal = item[0].Discipline;
        //    if (DisciplineVal !== null) {
        //        tempDisciplineVal = DisciplineVal.split(',');
        //    }
        //    setQuery(item[0].Query);
        //    processResponse(termStore, tempTechFunctionVal, tempDisciplineVal);
        //    // var executor = new SP.RequestExecutor(helpers.appweburl);
        //    // executor.executeAsync({
        //    //     url: helpers.taxonomyURLAppWebURL + "/_api/web/getFileByServerRelativeUrl('" + helpers.taxonomyURL + "')/$value?binaryStringResponseBody=true",
        //    //     // url: helpers.appweburl + "/_api/SP.AppContextSite(@target)/web/lists/getbytitle('Calendar')/items(" + iar.data.ID + ")/AttachmentFiles/add(FileName='" + fileAttachmentText + "')?@target='" + helpers.hostweburl + "'",
        //    //     method: "GET",
        //    //     headers: {
        //    //         "Accept": "application/json; odata=verbose"
        //    //     },
        //    //     success: function (data: any) {
        //    //         const results: any = processResponse(termStore);
        //    //     },
        //    //     error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
        //    //         console.log(thrownError);
        //    //     }
        //    // });
        //}).catch(e => {
        //    console.error('Fetching query failed!')
        //});
    }, []);

    useEffect(() => {
        let temp = [];
        setShowApply(false);
        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked) {
                setShowApply(true);
                temp.push({
                    name: o,
                    ID: taxonomyData[o].ID,
                    items: taxonomyData[o]
                });
            }
        });
        setDisciplineData(temp);

    }, [taxonomyData])

    useEffect(() => {
        let tempPTEData = [];
        Object.keys(disciplineData).forEach((z: any) => {
            Object.keys(disciplineData[z].items).forEach((o) => {
                if (disciplineData[z].items[o].checked) {
                    tempPTEData.push({
                        name: o,
                        ID: disciplineData[z].items[o].ID,
                        items: disciplineData[z].items[o]
                    })
                }
            });
        });

        setPTEData(tempPTEData);

    }, [disciplineData])



    //Get the data from search results
    useEffect(() => {
        //params.AppInsights.trackPageView(
        //    document.title,
        //    window.location.href,
        //    { userEmail: params.userEmail },
        //    null,
        //);
        setTimeout(() => {
            sp.crossDomainWeb(helpers.appweburl, helpers.sitecollectionurl).lists.getByTitle("SPoASiteName").items.select("Title,SiteURL").top(4999).get().then((response: any) => {
                response.map(o => {
                    if (o.SiteURL.Url.toLowerCase() === helpers.hostweburl.toLowerCase()) {
                        helpers.getUser((e: any) => {
                            console.log("Entries added in App Insights - KR page");
                            params.AppInsights.trackPageView(
                                o.Title,
                                window.location.href,
                                { userEmail: e.Email },
                                null,
                            );
                        });
                    }
                });
            });
        }, 3000);
        getInitialDataLoad();
    }, [])
    //Filtering the Function values
    //useEffect(() => {
    //    updateResultsforFunction();
    //}, [functionFilters]);
    ////Filtering the Discipline values
    //useEffect(() => {
    //    updateResultsforDiscipline();
    //}, [disciplineFilters]);
    ////Filtering the PTE values
    //useEffect(() => {
    //    updateResultsforPTEFilters();
    //}, [pteAreaFilters]);

    // Function to handle pagination click
    const handlePaginationClick = (pageNumber) => {
        setaActivePage(pageNumber);
        filterData(pageNumber);
        window.scrollTo(0, 0)
    };

    // Generate pagination items
    const paginationItems = [];

    // Logic to handle first and last page buttons
    const handleFirstPage = () => {
        setaActivePage(1);
        filterData(1);
        window.scrollTo(0, 0)
    };

    const handleLastPage = () => {
        setaActivePage(pageCount);
        filterData(pageCount);
        window.scrollTo(0, 0)
    };

    // Logic to handle skipping pages with ellipsis
    const skipPages = (start, end) => {
        for (let page = start; page <= end; page++) {
            paginationItems.push(
                <Pagination.Item
                    key={page}
                    active={page === activePage}
                    onClick={() => handlePaginationClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }
        paginationItems.push(<Pagination.Ellipsis key="ellipsis" />);
    };

    if (pageCount <= 5) {
        // If total pages are less than or equal to 5, display all pages
        for (let page = 1; page <= pageCount; page++) {
            paginationItems.push(
                <Pagination.Item
                    key={page}
                    active={page === activePage}
                    onClick={() => handlePaginationClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }
    } else {
        // If total pages are greater than 5
        if (activePage <= 3) {
            // Display first 5 pages
            skipPages(1, 5);
        } else if (activePage >= pageCount - 2) {
            // Display last 5 pages
            skipPages(pageCount - 4, pageCount);
        } else {
            // Display pages around the active page
            paginationItems.push(
                <Pagination.Item
                    key={1}
                    active={1 === activePage}
                    onClick={() => handlePaginationClick(1)}
                >
                    {1}
                </Pagination.Item>
            );
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
            for (let page = activePage - 1; page <= activePage + 1; page++) {
                paginationItems.push(
                    <Pagination.Item
                        key={page}
                        active={page === activePage}
                        onClick={() => handlePaginationClick(page)}
                    >
                        {page}
                    </Pagination.Item>
                );
            }
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
            paginationItems.push(
                <Pagination.Item
                    key={pageCount}
                    active={pageCount === activePage}
                    onClick={() => handlePaginationClick(pageCount)}
                >
                    {pageCount}
                </Pagination.Item>
            );
        }
    }

    return (
        <div id="knowledge-repository" onClick={() => {
            setFunctionFilter(false);
            setDisciplineFilter(false);
            setPteAreaFilter(false);
        }}>
            <KrEdit showEditDialog={showEditDialog} queryFromKR={query} queryIDFromKR={queryID} taxonomyDataFromKR={initalData} setShowEditDialog={setShowEditDialog} getInitialDataLoad={getInitialDataLoad} setShowLoading={setShowLoading} />
            {showLoading ?
                <div className="allDisciplineMembers-loading container">
                    <SkeletonTheme color="#eeeeee" highlightColor="#ffffff">
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                        <Skeleton height={66}></Skeleton>
                    </SkeletonTheme>
                </div> :

                <div className="container">
                    {filecheck.showError ? <Alert className="filter-alert" variant={filecheck.type}> {filecheck.message} </Alert> : null}
                    <div>
                        {params.editMode ? <a className='edit-mode' onClick={() => setShowEditDialog(true)}> Edit View </a> : ""}
                    </div>
                    {
                        results.length > 0 ?
                            <div className="export-excel">
                                <ExcelFile element={<button className="btn btn-info">Export to Excel</button>} filename={"Knowledge Repository" + "_" + currentDateTime.toUTCString()}>
                                    <ExcelSheet data={results} name="Knowledge Repository">
                                        <ExcelColumn label="Document Title" value="title" />
                                        <ExcelColumn label="Function" value="techFunction" />
                                        <ExcelColumn label="Discipline" value="discipline" />
                                        <ExcelColumn label="PTE Area" value="pteArea" />
                                        <ExcelColumn label="Created By" value="createdBy" />
                                        <ExcelColumn label="Approved On" value="approvedOn" />
                                        <ExcelColumn label="Valid Till" value="validTill" />
                                        <ExcelColumn label="Additional Expertise Area" value="additionalExpertiseArea" />
                                        <ExcelColumn label="Approved By" value="approvedBy" />
                                        <ExcelColumn label="File Path" value="fileViewPath" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            : ""
                    }
                    <div className="kr-header">
                        <div> Document Title </div>
                        <div>
                            Function
                            <span onClick={(e) => { e.stopPropagation(); setFunctionFilter(!functionFilter); setDisciplineFilter(false); setPteAreaFilter(false); }}> <IoIosArrowDown /> </span>
                            <div className={!functionFilter ? "filter-container" : "filter-container filter-container-show"} onClick={(e) => { e.stopPropagation() }}>
                                {/* <span onClick={() => {
                                    let t = [...functionFilters];
                                    t.map(o => o.checked = true);
                                    setFunctionFilters(t);
                                }}> Select All </span> */}
                                <Form>
                                    {
                                        Object.keys(taxonomyData).length ?
                                            Object.keys(taxonomyData).map((key: any, i) => {
                                                return (
                                                    <div key={i}>
                                                        <Form.Group controlId={"/Guid(" + taxonomyData[key].ID + ")/"} >
                                                            <Form.Check type="checkbox" id={"/Guid(" + taxonomyData[key].ID + ")/"} label={key} checked={taxonomyData[key].checked} onClick={() => {
                                                                const temp = { ...taxonomyData };
                                                                temp[key].checked = !temp[key].checked;
                                                                setTaxonomyData(temp);
                                                            }} />
                                                        </Form.Group>
                                                    </div>
                                                )
                                            })
                                            : "Loading, please wait..."
                                    }

                                </Form>
                            </div>
                        </div>
                        <div> Discipline
                            <span onClick={(e) => { e.stopPropagation(); setDisciplineFilter(!disciplineFilter); setFunctionFilter(false); setPteAreaFilter(false); }}> <IoIosArrowDown /> </span>
                            <div className={!disciplineFilter ? "filter-container" : "filter-container filter-container-show"} onClick={(e) => { e.stopPropagation() }}>
                                {/* <span onClick={() => {
                                    let t = [...disciplineFilters];
                                    t.map(o => o.checked = true);
                                    setDisciplineFilters(t);
                                }}> Select All </span> */}
                                <Form>
                                    {
                                        disciplineData.length ?
                                            disciplineData.map((obj: any, index: number) => {

                                                return (
                                                    <div key={index}>
                                                        <div className="title">{obj.name}</div>
                                                        <div className="items">
                                                            {
                                                                obj.items.items.length ?
                                                                    Object.keys(obj.items).map((innerKey: any, innerIndex: number) => {

                                                                        if (['ID', 'checked', 'items'].includes(innerKey) === false) {

                                                                            const innerObj = disciplineData[index].items[innerKey];
                                                                            return (
                                                                                <div key={innerIndex}>
                                                                                    <Form.Group controlId={"/Guid(" + innerIndex + ")/"}>
                                                                                        <Form.Check type="checkbox" id={'discipline-' + "/Guid(" + innerIndex + ")/"} label={innerKey} checked={innerObj.checked} onClick={() => {
                                                                                            const temp = [...disciplineData];
                                                                                            temp[index].items[innerKey].checked = !temp[index].items[innerKey].checked;
                                                                                            setDisciplineData(temp);
                                                                                        }} />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }) : "No disciplines found"
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : "Please select one Function"
                                    }
                                </Form>
                            </div>
                        </div>
                        <div> PTE Area
                            <span onClick={(e) => { e.stopPropagation(); setPteAreaFilter(!pteAreaFilter); setDisciplineFilter(false); setFunctionFilter(false); }}> <IoIosArrowDown /> </span>
                            <div className={!pteAreaFilter ? "filter-container" : "filter-container filter-container-show"} onClick={(e) => { e.stopPropagation() }}>
                                {/* <span onClick={() => {
                                    let t = [...pteData];
                                    Object.keys(t[0]).map( key => { 
                                        Object.keys(t[0][key].items).map( o => t[0][key].items[o].checked = true);
                                    })
                                    setPTEData(t);
                                }}> Select All </span> */}
                                <Form>
                                    {
                                        pteData.length ?
                                            pteData.map((obj: any, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="title">{obj.name}</div>
                                                        <div className="items">
                                                            {
                                                                Object.keys(obj.items).length > 3 ?
                                                                    Object.keys(obj.items).map((innerKey: any, innerIndex: number) => {
                                                                        if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                                                                            const innerObj = pteData[index].items[innerKey];
                                                                            return (
                                                                                <div key={innerIndex}>
                                                                                    <Form.Group controlId={"/Guid(" + innerIndex + ")/"} >
                                                                                        <Form.Check type="checkbox" id={'pte-' + "/Guid(" + innerIndex + ")/"} label={innerKey} checked={innerObj.checked} onClick={() => {
                                                                                            const temp = [...pteData];
                                                                                            temp[index].items[innerKey].checked = !temp[index].items[innerKey].checked;
                                                                                            setPTEData(temp);
                                                                                        }} />
                                                                                    </Form.Group>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }) : "No PTE found"
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            : "Please select one Discipline"

                                    }
                                </Form>
                            </div>


                        </div>
                        {
                            showApply ?
                                <div className='apply-filter' onClick={() => {
                                    setFunctionFilter(false);
                                    setDisciplineFilter(false);
                                    setPteAreaFilter(false);
                                    buildQueryString();
                                }}> Apply </div>
                                :
                                ""
                        }

                    </div>
                    <div className="kr-body">
                        {
                            documentToShow.length > 0
                                ?
                                documentToShow.map((o: panelItem, i) => {
                                    let documentTitle = '';
                                    panelState.map((objval, ij) => {
                                        if (objval === true) {
                                            if (ij === i) {
                                                documentTitle = o.title;
                                            }
                                        }
                                    })
                                    return (
                                        <div key={i} className={panelState[i] ? "kr-panel-container open" : "kr-panel-container"}>
                                            <div onClick={() => updatePanel(i)} className="kr-panel-header">
                                                {o.fileViewPath != null ? <span className="doc_file_icon">
                                                    <a href={o.fileViewPath} target='_blank' rel='noopener noreferrer' >
                                                        <img src={o.fileType} alt="icon" onClick={(e) => {
                                                            sendToAppInsights('Knowledge Repository', o.appInsightFilePath);
                                                            e.stopPropagation();
                                                        }} /></a></span> : <span className="doc_file_icon">
                                                    <a href={o.fileViewPath} target='_blank' rel='noopener noreferrer' >
                                                        <img src={o.fileType} alt="icon" onClick={(e) => {
                                                            sendToAppInsights('Knowledge Repository', o.appInsightFilePath);
                                                            e.stopPropagation();
                                                        }} /></a></span>}
                                                {o.fileViewPath != null ? <div className="docFileName"
                                                    onClick={(e) => {
                                                        sendToAppInsights('Knowledge Repository', o.appInsightFilePath);
                                                        e.stopPropagation();
                                                    }}>
                                                    <a href={o.fileViewPath} target='_blank' rel='noopener noreferrer' >
                                                        {documentTitle ? documentTitle : truncateString(o.title, 120)}
                                                    </a></div> : <div className="docFileName"
                                                        onClick={(e) => {
                                                            sendToAppInsights('Knowledge Repository', o.appInsightFilePath);
                                                            e.stopPropagation();
                                                        }}>
                                                    <a href={o.fileViewPath} target='_blank' rel='noopener noreferrer' >
                                                        {documentTitle ? documentTitle : truncateString(o.title, 120)}
                                                    </a></div>}
                                                <div>
                                                    {o.techFunction}
                                                </div>
                                                <div>
                                                    {o.discipline}
                                                </div>
                                                <div>
                                                    {o.pteArea}
                                                </div>
                                                <div className="icons">
                                                    <div className="download">
                                                        <FiDownload size={20} onClick={(e) => {
                                                            sendToAppInsights('Knowledge Repository', o.appInsightFilePath);
                                                            e.stopPropagation();
                                                            window.open(o.filePath, '_blank');
                                                            return false;
                                                        }} />
                                                    </div>
                                                    <div className="arrow">
                                                        {panelState[i] ? <IoIosArrowUp size={25} /> : <IoIosArrowDown size={25} />}
                                                    </div>
                                                </div>

                                            </div>

                                            <Collapse in={panelState[i]}>
                                                <div className="kr-panel-body">
                                                    {/* kr-panel-inner - this is added for not to override collapse styles */}
                                                    <div className="kr-panel-inner">
                                                        <div className="left">
                                                            {o.createdBy !== null ? <div> <span className="created-by">Created By : </span> <span> {o.createdBy} </span></div> : "-"}
                                                            {o.approvedOn !== null ? < div> <span className="approved-on" >Approved On : </span> <span> {o.approvedOn} </span></div> : "-"}
                                                            {o.validTill !== null ? <div> <span className="valid-till" >Valid Till : </span> <span> {o.validTill} </span></div> : "-"}
                                                        </div>
                                                        <div className="right">
                                                            {o.primaryExpertiseArea !== null ? <div className="pteDiv"> <div className="pte">Primary Expertise Area : </div> <div className="pteVal"> {o.primaryExpertiseArea} </div></div> : "-"}
                                                            {o.additionalExpertiseArea !== null ? <div className="add-expertise-areaDiv"> <div className="add-expertise-area">Additional Expertise Area : </div> <div className="add-expertise-area-val"> {o.additionalExpertiseArea} </div></div> : "-"}
                                                            {o.approvedBy !== null ? <div> <span className="approved-by" >Approved By : </span> <span> {o.approvedBy} </span></div> : "-"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    )
                                }) : <div className="no-mentors">
                                    <div>
                                        There are no KR documents available for this community.
                                    </div>
                                </div>
                        }

                    </div>

                    {(!showLoading && results.length) ?
                        <div>
                            <Pagination className="pagination">
                                <Pagination.First onClick={handleFirstPage} />
                                <Pagination.Prev
                                    onClick={() => handlePaginationClick(activePage - 1)}
                                    disabled={activePage === 1}
                                />
                                {paginationItems}
                                <Pagination.Next
                                    onClick={() => handlePaginationClick(activePage + 1)}
                                    disabled={activePage === pageCount}
                                />
                                <Pagination.Last onClick={handleLastPage} />
                            </Pagination>
                        </div>
                        : ""
                    }
                    {/*<Pagination className="pagination">*/}
                    {/*    {*/}
                    {/*        (!showLoading && results.length) ?*/}
                    {/*            [...Array(pageCount)].map((x, i) => {*/}
                    {/*                return (*/}
                    {/*                    <Pagination.Item key={i + 1} active={i + 1 === activePage} onClick={() => onPaginationClick(i)}> {i + 1} </Pagination.Item>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*            : ""*/}
                    {/*    }*/}
                    {/*</Pagination>*/}

                </div>
            }



        </div>
    )

    //Truncate the title
    function truncateString(str: string, num: number) {
        if (str.length <= num) {
            return str
        }
        return str.slice(0, num) + '...'
    }

    //Filtering the Page count data
    function filterData(page: number) {
        setDocumentToShow([]);
        setDocumentToShow(results.slice(recordsPerPage * page - recordsPerPage, recordsPerPage * page));
        setPanelState(new Array(documentToShow.length).fill(false));
    }
    //Pagination click
    function onPaginationClick(i: any) {
        setaActivePage(i + 1);
        filterData(i + 1);
        window.scrollTo(0, 0)
    }

    //Updating the panel entried based on user click
    function updatePanel(index) {
        let temp = [...panelState];
        temp[index] = !temp[index];
        setPanelState(temp);
        //sendToAppInsights('Knowledge Repository');
    }

    //Retriving the data from search using KR refinable filters

    function getInitialDataLoad() {
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.filter("Title eq 'KR Listview'").get().then((item: any) => {
            setQueryID(item[0].ID);
            let TechFunctionVal = item[0].TechFunction;
            if (TechFunctionVal !== null) {
                tempTechFunctionVal = TechFunctionVal.split(';');
            }
            let DisciplineVal = item[0].Discipline;
            if (DisciplineVal !== null) {
                tempDisciplineVal = DisciplineVal.split(';');
            }
            setQuery(item[0].Query);
            if (termStore.length !== undefined) {
                processResponse(termStore, tempTechFunctionVal, tempDisciplineVal);
                getSearchQuery();
            } else {
                //PHA Setup
                var executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync({
                    url: helpers.taxonomyURLAppWebURL + "/_api/web/getFileByServerRelativeUrl('" + helpers.taxonomyURL + "')/$value?binaryStringResponseBody=true",
                    method: "GET",
                    headers: {
                        "Accept": "application/json; odata=verbose"
                    },
                    success: function (data: any) {
                        processResponse(JSON.parse(data.body), tempTechFunctionVal, tempDisciplineVal);
                        getSearchQuery();
                    },
                    error: function onQueryErrorAQ(xhr, ajaxOptions, thrownError) {
                        console.log(thrownError);
                    }
                });
            }
        }).catch(e => {
            console.error('Fetching query failed!')
        });
    }

    function getSearchQuery(finalQueryString?: string) {
        tempTechFunctionQuery = [];
        tempDisciplineQuery = [];
        tempPteQuery = [];
        spoaDefaultFunction = "";
        spoaDefaultDiscipline = "";
        spoaDefaultPTE = "";

        helpers.getKRListView("SpoAPageData", ((items: Array<{
            Query: string,
            TechFunction: string,
            Discipline: string,
            PTEVal: string
        }>) => {
            console.log(items);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    let krQuery = items[i].Query;
                    if (finalQueryString) {
                        KR_Search_query = finalQueryString;
                        loadKRresults(finalQueryString, rowLimit, startRow);
                    }
                    else if (krQuery !== "null" && krQuery !== "" && krQuery !== null) {
                        if (items[i].TechFunction !== "" && items[i].TechFunction !== null && items[i].TechFunction !== "null") {
                            tempTechFunctionQuery = items[i].TechFunction.split(';');
                            spoaDefaultFunction = items[i].TechFunction;
                        }
                        if (items[i].Discipline !== "" && items[i].Discipline !== null && items[i].Discipline !== "null") {
                            tempDisciplineQuery = items[i].Discipline.split(';');
                            spoaDefaultDiscipline = items[i].Discipline;
                        }
                        if (items[i].PTEVal !== "" && items[i].PTEVal !== null && items[i].PTEVal !== "null") {
                            tempPteQuery = items[i].PTEVal.split(';');
                            spoaDefaultPTE = items[i].PTEVal;
                        }
                        KR_Search_query = krQuery;
                        loadKRresults(krQuery, rowLimit, startRow);
                    }
                    else {
                        setShowLoading(false);
                    }
                }
            }
            else {
                setShowLoading(false);
            }
        }), "Title,Query,Link,Section,TechFunction,Discipline,PTEVal", "Section eq 'KR Listview'");

    }

    function loadKRresults(disc_configured_query, rowLimit, startRow) {
        var searchUrl = helpers.appweburl + "/_api/search/query?querytext=" + disc_configured_query + "&rowlimit=" + rowLimit + "&startrow=" + startRow + "";
        var executor = new SP.RequestExecutor(helpers.appweburl);
        executor.executeAsync(
            {
                url: searchUrl,
                method: "GET",
                headers: { "Accept": "application/json; odata=verbose" },
                success: onGetKRDocResults,
                error: onGetSearchResultsFail
            }
        );
    }

    function onGetKRDocResults(data) {
        let jsonObject = JSON.parse(data.body);
        var relevantResults = jsonObject.d.query.PrimaryQueryResult.RelevantResults;
        allKRDocResults = allKRDocResults.concat(relevantResults.Table.Rows.results);
        if (relevantResults.TotalRows > startRow + relevantResults.RowCount) {
            startRow = startRow + relevantResults.RowCount;
            loadKRresults(KR_Search_query, rowLimit, startRow);
        } else {
            onGetSearchResultsSuccess(allKRDocResults);
        }
    }


    function onGetSearchResultsSuccess(data) {
        //let jsonObject = JSON.parse(data.body);
        //let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
        let jsonresults = data;
        let items = [];
        let tempFilterResults: any = [];
        let tempObjResults = [];
        let tempPteQuerySet = [];
        let tempDisciplineQuerySet = [];
        let tempTechFunctionQSet = [];
        let techincalFunction = '';
        let disciplineVal = '';
        let pteAreaVal = '';
        let additionalExpertiseArea = '';
        let approvedBy = '';
        let tempTFFilters = [];
        let tempDiscFilters = [];
        let tempPTEFilters = [];
        let tempFunctionFilters = [];
        let tempDisciplineFilters = [];
        let tempPTEAreaFilters = [];
        let fileType = '';
        let approvedOn = '';
        let validTill = '';
        var filePath = '';
        var appInsightFilePath = '';
        let doctitle = '';
        let docCreatedBy = '';
        let docfileViewPath = '';
        let docLinksfileViewPath = '';
        let docFileName = '';
        let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        if (jsonresults.length > 0 && jsonresults !== null) {
            for (let i = 0; i < jsonresults.length; i++) {
                techincalFunction = '';
                disciplineVal = '';
                pteAreaVal = '';
                fileType = '';
                additionalExpertiseArea = '';
                tempTFFilters = [];
                tempDiscFilters = [];
                tempPTEFilters = [];
                approvedOn = '';
                validTill = '';
                filePath = '';
                appInsightFilePath = '';
                doctitle = '';
                docCreatedBy = '';
                docfileViewPath = '';
                docFileName = '';
                docLinksfileViewPath = '';
                for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                    if (jsonresults[i].Cells.results[j].Key === "owstaxIdKRExpertiseAreaFunction") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            techincalFunction = getTermValue(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "owstaxIdKRExpertiseAreaDiscipline") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            disciplineVal = getTermValue(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "owstaxIdKRExpertiseAreaPTE") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            pteAreaVal = getTermValue(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "owstaxIdKRAdditionalExpertiseAreas") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            additionalExpertiseArea = getTermValue(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "ApprovedByOWSUSER") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            approvedBy = jsonresults[i].Cells.results[j].Value;
                            let approvedByVal = approvedBy.split("|");
                            approvedBy = approvedByVal[1].trim();
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "fileextension") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            fileType = fileIcons(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "ApprovedOnOWSDATE") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            approvedOn = jsonresults[i].Cells.results[j].Value;
                            var approvedOnDate = new Date(approvedOn);
                            approvedOn = approvedOnDate.getDate() + " " + months[approvedOnDate.getMonth()] + " " + approvedOnDate.getFullYear();
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "ValidToOWSDATE") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            validTill = jsonresults[i].Cells.results[j].Value;
                            var validTillDate = new Date(validTill);
                            validTill = months[validTillDate.getMonth()] + " " + validTillDate.getFullYear();
                        }
                    }

                    if (jsonresults[i].Cells.results[j].Key === "FileName") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            docLinksfileViewPath = jsonresults[i].Cells.results[j].Value;
                            docLinksfileViewPath = docLinksfileViewPath.replace(/<a href="[/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            docLinksfileViewPath = docLinksfileViewPath.replace(/<a href="[/&#58;x&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            docLinksfileViewPath = docLinksfileViewPath.replace(/<a href="[/&#58;w&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            docLinksfileViewPath = docLinksfileViewPath.replace(/<a href="[/&#58;p&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            docLinksfileViewPath = docLinksfileViewPath.replace(/<a href="[/&#58;b&#58;/r/]*sites/g, '<a href="' + helpers.rootSiteCollection);
                            if (docLinksfileViewPath.includes(".jpg") === true || docLinksfileViewPath.includes(".png") === true || docLinksfileViewPath.includes(".zip") === true || docLinksfileViewPath.includes(".msg") === true) {
                                docLinksfileViewPath = "https://eu001-sp.shell.com/sites/AAAAB2112/Knowledge%20Items/" + jsonresults[i].Cells.results[j].Value;
                            }
                            docFileName = fileIcons(jsonresults[i].Cells.results[j].Value);
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "Path") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            filePath = jsonresults[i].Cells.results[j].Value;
                            appInsightFilePath = jsonresults[i].Cells.results[j].Value;
                            if (filePath.includes("Knowledge Links") === true) {
                                filePath = docLinksfileViewPath; //helpers.krDocURL + "_layouts/download.aspx?SourceUrl=" + docLinksfileViewPath;
                            }
                            else {
                                if (filePath.includes("pptx") === true || filePath.includes("ppt") === true || filePath.includes("zip") === true) {
                                    filePath = jsonresults[i].Cells.results[j].Value;
                                }
                                else if (docLinksfileViewPath.includes(".jpg") === true || docLinksfileViewPath.includes(".png") === true || docLinksfileViewPath.includes(".zip") === true || docLinksfileViewPath.includes(".msg") === true) {
                                    filePath = helpers.krDocURL + "_layouts/download.aspx?SourceUrl=" + docLinksfileViewPath;
                                }
                                else {
                                    filePath = helpers.krDocURL + "_layouts/download.aspx?SourceUrl=" + jsonresults[i].Cells.results[j].Value;
                                }

                            }
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "Title") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            doctitle = jsonresults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "CreatedBy") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            docCreatedBy = jsonresults[i].Cells.results[j].Value;
                        }
                    }
                    if (jsonresults[i].Cells.results[j].Key === "ServerRedirectedEmbedURL") {
                        if (jsonresults[i].Cells.results[j].Value !== "" && jsonresults[i].Cells.results[j].Value !== null) {
                            docfileViewPath = jsonresults[i].Cells.results[j].Value;
                            docfileViewPath = docfileViewPath.split('&action=')[0] + "&action=embedview";
                        }
                        else {
                            docfileViewPath = docLinksfileViewPath;
                        }
                    }
                }
                items.push({
                    title: doctitle,
                    filePath: filePath,
                    fileType: fileType,
                    techFunction: techincalFunction,
                    discipline: disciplineVal,
                    pteArea: pteAreaVal,
                    createdBy: docCreatedBy,
                    approvedOn: approvedOn,
                    validTill: validTill,
                    primaryExpertiseArea: pteAreaVal,
                    additionalExpertiseArea: additionalExpertiseArea,
                    approvedBy: approvedBy,
                    fileViewPath: docfileViewPath,
                    appInsightFilePath: appInsightFilePath,
                });
            }

            //let tempTechFun = getFilterTermVal(tempFunctionFilters);
            //let tempDiscipline = getFilterTermVal(tempDisciplineFilters);
            //let tempPTE = getFilterTermVal(tempPTEAreaFilters);

            //setFunctionFilters(tempTechFun);
            //setDisciplineFilters(tempDiscipline);
            //setPteAreaFilters(tempPTE);

            if (tempTechFunctionQuery.length === 0) {
                if (Object.keys(taxonomyData).length > 0) {
                    Object.keys(taxonomyData).forEach((o) => {
                        if (taxonomyData[o].checked === true) {
                            tempFilterResults.push(o);
                            tempTechFunctionQSet.push(o);
                        }
                    });
                }
                else {
                    //console.log(tempTechFunctionQuery);
                    tempTechFunctionQSet = tempTechFunctionQuery;
                }
            }
            else {
                //console.log(tempTechFunctionQuery);
                tempTechFunctionQSet = tempTechFunctionQuery;
            }
            if (tempDisciplineQuery.length === 0) {
                if (disciplineData.length > 0) {
                    Object.keys(disciplineData).forEach((z: any) => {
                        Object.keys(disciplineData[z].items).forEach((o) => {
                            if (disciplineData[z].items[o].checked) {
                                tempFilterResults.push(o);
                                tempDisciplineQuerySet.push(o);
                            }
                        })
                    });
                }
                else {
                    // console.log(tempDisciplineQuery);
                    tempDisciplineQuerySet = tempDisciplineQuery;
                }
            } else {
                // console.log(tempDisciplineQuery);
                tempDisciplineQuerySet = tempDisciplineQuery;
            }
            if (tempPteQuery.length === 0) {
                if (pteData.length > 0) {
                    Object.keys(pteData).forEach((z: any) => {
                        Object.keys(pteData[z].items).forEach((o) => {
                            if (pteData[z].items[o].checked) {
                                tempFilterResults.push(o);
                                tempPteQuerySet.push(o);
                            }
                        })
                    });
                } else {
                    // console.log(tempPteQuery);
                    tempPteQuerySet = tempPteQuery;
                }
            } else {
                // console.log(tempPteQuery);
                tempPteQuerySet = tempPteQuery;
            }
            tempObjResults = [...items].slice();
            if (tempPteQuerySet.length > 0) {
                tempFilterResults = tempPteQuerySet;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.pteArea.indexOf(';') > -1) {
                        let tempDiscFilters = o.pteArea.split(";").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.pteArea)
                    }
                });
            }
            //console.log(tempObjResults);
            if (tempDisciplineQuerySet.length > 0) {
                tempFilterResults = tempDisciplineQuerySet;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.discipline.indexOf(';') > -1) {
                        let tempDiscFilters = o.discipline.split(";").map(item => item.trim());
                        tempDiscFilters = tempDiscFilters.map(tempObjDisc => tempFilterResults.includes(tempObjDisc))
                        return tempDiscFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.discipline)
                    }
                });
            }
            //console.log(tempObjResults);
            if (tempTechFunctionQSet.length > 0) {
                tempFilterResults = tempTechFunctionQSet;
                tempObjResults = tempObjResults.filter(o => {
                    if (o.techFunction.indexOf(';') > -1) {
                        let tempFunctionFilters = o.techFunction.split(";").map(item => item.trim());
                        tempFunctionFilters = tempFunctionFilters.map(tempObj => tempFilterResults.includes(tempObj))
                        return tempFunctionFilters.includes(true)
                    }
                    else {
                        return tempFilterResults.includes(o.techFunction)
                    }
                });
            }
            //console.log(tempObjResults);
            setResults(tempObjResults);
            setPageCount(Math.ceil(tempObjResults.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
            setDocumentToShow([]);
            setDocumentToShow(tempObjResults.slice(0, recordsPerPage));
            setaActivePage(1);
            setShowLoading(false);
            setFileCheck({
                showError: true, type: "info", message: "Results shown are based on predefined Function and Discipline. To modify your search please apply filter: First select Function, followed by Discipline and PTE Area."
            });
            //setTimeout(() => {
            //    setFileCheck({ showError: false });
            //}, 9000)

        }
        else {
            setPageCount(0);
            setDocumentToShow([]);
            setShowLoading(false);
            setaActivePage(1);
        }
    }
    function onGetSearchResultsFail(data, errorCode, errorMessage) {
        console.log('An error occurred whilst searching for related content - ' + errorMessage);
    }

    ////Filtering the PTE results when user select/unselect the checkboxes
    //function updateResultsforPTEFilters() {
    //    let temp = [];
    //    let tempDisciplineFilters = [];
    //    let tFFilters = functionFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    let discFilters = disciplineFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    let pteFilters = pteAreaFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    if (discFilters.every(el => el === '') !== true && pteFilters.every(el => el === '') !== true) {
    //        if (temp.length === 0) {
    //            setResults(results);
    //            setPteAreaFilters(tempDisciplineFilters);
    //            setPageCount(Math.ceil(results.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //            setDocumentToShow(results.slice(0, recordsPerPage));
    //            setPanelState(new Array(documentToShow.length).fill(false));
    //            setaActivePage(1);
    //            return;
    //        }
    //        let tempResults = [...results];
    //        //tempResults = tempResults.filter(o => (temp.includes(o.discipline)));
    //        tempResults = tempResults.filter(o => {
    //            if (o.pteArea.indexOf(',') > -1) {
    //                let tempDiscFilters = o.pteArea.split(",").map(item => item.trim());
    //                tempDiscFilters = tempDiscFilters.map(tempObjDisc => temp.includes(tempObjDisc))
    //                return tempDiscFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.pteArea)
    //            }
    //        });

    //        tempResults = tempResults.filter(o => {
    //            if (o.discipline.indexOf(',') > -1) {
    //                let tempDiscFilters = o.discipline.split(",").map(item => item.trim());
    //                tempDiscFilters = tempDiscFilters.map(tempObjDisc => temp.includes(tempObjDisc))
    //                return tempDiscFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.discipline)
    //            }
    //        });

    //        tempResults = tempResults.filter(o => {
    //            if (o.techFunction.indexOf(',') > -1) {
    //                let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
    //                tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //                return tempFunctionFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.techFunction)
    //            }
    //        });
    //        // setPteAreaFilters(getFilterTermVal(tempDisciplineFilters));
    //        setPageCount(Math.ceil(tempResults.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //        setDocumentToShow(tempResults.slice(0, recordsPerPage));
    //        setPanelState(new Array(documentToShow.length).fill(false));
    //        setaActivePage(1);
    //    }
    //    else {
    //        if (temp.length === 0) {
    //            setResults(results);
    //            //setPteAreaFilters(tempDisciplineFilters);
    //            setPageCount(Math.ceil(results.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //            setDocumentToShow(results.slice(0, recordsPerPage));
    //            setaActivePage(1);
    //            return;
    //        }
    //        let tempResults = [...results];
    //        //tempResults = tempResults.filter(o => (temp.includes(o.techFunction)));
    //        tempResults = tempResults.filter(o => {
    //            if (o.discipline.indexOf(',') > -1) {
    //                let tempFunctionFilters = o.discipline.split(",").map(item => item.trim());
    //                tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //                return tempFunctionFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.discipline)
    //            }
    //        });

    //        tempResults = tempResults.filter(o => {
    //            if (o.techFunction.indexOf(',') > -1) {
    //                let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
    //                tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //                return tempFunctionFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.techFunction)
    //            }
    //        });

    //        setPageCount(Math.ceil(tempResults.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //        setDocumentToShow(tempResults.slice(0, recordsPerPage));
    //        setPanelState(new Array(documentToShow.length).fill(false));
    //        setaActivePage(1);
    //    }
    //}

    ////Filtering the Discipline results when user select/unselect the checkboxes
    //function updateResultsforDiscipline() {

    //    let temp = [];
    //    let tempDisciplineFilters = [];
    //    let tFFilters = functionFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    let discFilers = disciplineFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    if (discFilers.every(el => el === '') !== true) {
    //        if (temp.length === 0) {
    //            setResults(results);
    //            setPteAreaFilters(tempDisciplineFilters);
    //            setPageCount(Math.ceil(results.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //            setDocumentToShow(results.slice(0, recordsPerPage));
    //            setPanelState(new Array(documentToShow.length).fill(false));
    //            setaActivePage(1);
    //            return;
    //        }
    //        let tempResults = [...results];
    //        //tempResults = tempResults.filter(o => (temp.includes(o.discipline)));
    //        tempResults = tempResults.filter(o => {
    //            if (o.discipline.indexOf(',') > -1) {
    //                let tempDiscFilters = o.discipline.split(",").map(item => item.trim());
    //                tempDiscFilters = tempDiscFilters.map(tempObjDisc => temp.includes(tempObjDisc))
    //                return tempDiscFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.discipline)
    //            }
    //        });

    //        tempResults = tempResults.filter(o => {
    //            if (o.techFunction.indexOf(',') > -1) {
    //                let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
    //                tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //                return tempFunctionFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.techFunction)
    //            }
    //        });

    //        for (let i = 0; i < tempResults.length; i++) {
    //            let tempDiscFilters = tempResults[i].pteArea.split(",").map(item => item.trim());
    //            if (tempDiscFilters.length <= 1) {
    //                tempDisciplineFilters.push({ id: tempResults[i].pteArea, label: tempResults[i].pteArea, checked: false });
    //            }
    //            else {
    //                for (let j = 0; j < tempDiscFilters.length; j++) {
    //                    tempDisciplineFilters.push({ id: tempDiscFilters[j], label: tempDiscFilters[j], checked: false });
    //                }
    //            }
    //        }
    //        setPteAreaFilters(getFilterTermVal(tempDisciplineFilters));
    //        setPageCount(Math.ceil(tempResults.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //        setDocumentToShow(tempResults.slice(0, recordsPerPage));
    //        setPanelState(new Array(documentToShow.length).fill(false));
    //        setaActivePage(1);
    //    } else {
    //        if (temp.length === 0) {
    //            setResults(results);
    //            setPteAreaFilters(tempDisciplineFilters);
    //            setPageCount(Math.ceil(results.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //            setDocumentToShow(results.slice(0, recordsPerPage));
    //            setPanelState(new Array(documentToShow.length).fill(false));
    //            setaActivePage(1);
    //            return;
    //        }
    //        let tempResults = [...results];
    //        tempResults = tempResults.filter(o => {
    //            if (o.techFunction.indexOf(',') > -1) {
    //                let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
    //                tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //                return tempFunctionFilters.includes(true)
    //            }
    //            else {
    //                return temp.includes(o.techFunction)
    //            }
    //        });
    //        setPageCount(Math.ceil(tempResults.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //        setDocumentToShow(tempResults.slice(0, recordsPerPage));
    //        setPanelState(new Array(documentToShow.length).fill(false));
    //        setaActivePage(1);
    //    }
    //}

    ////Filtering the Techincal function results when user select/unselect the checkboxes
    //function updateResultsforFunction() {
    //    let temp = [];
    //    let tempDisciplineFilters = [];
    //    let tFFilters = functionFilters.map(o => o.checked ? temp.push(o.label) : '');
    //    if (temp.length === 0) {
    //        setResults(results);
    //        setDisciplineFilters(tempDisciplineFilters);
    //        setPageCount(Math.ceil(results.length / recordsPerPage)); //recordsPerPage is a global variable which decides the number of records per page.
    //        setDocumentToShow(results.slice(0, recordsPerPage));
    //        setPanelState(new Array(documentToShow.length).fill(false));
    //        setaActivePage(1);
    //        return;
    //    }
    //    let tempResults = [...results];
    //    tempResults = tempResults.filter(o => {
    //        if (o.techFunction.indexOf(',') > -1) {
    //            let tempFunctionFilters = o.techFunction.split(",").map(item => item.trim());
    //            tempFunctionFilters = tempFunctionFilters.map(tempObj => temp.includes(tempObj))
    //            return tempFunctionFilters.includes(true)
    //        }
    //        else {
    //            return temp.includes(o.techFunction)
    //        }
    //    });
    //    for (let i = 0; i < tempResults.length; i++) {
    //        let tempDiscFilters = tempResults[i].discipline.split(",").map(item => item.trim());
    //        if (tempDiscFilters.length <= 1) {
    //            tempDisciplineFilters.push({ id: tempResults[i].discipline, label: tempResults[i].discipline, checked: false });
    //        }
    //        else {
    //            for (let j = 0; j < tempDiscFilters.length; j++) {
    //                tempDisciplineFilters.push({ id: tempDiscFilters[j], label: tempDiscFilters[j], checked: false });
    //            }
    //        }
    //    }
    //    setDisciplineFilters(getFilterTermVal(tempDisciplineFilters));
    //    setPageCount(Math.ceil(tempResults.length / recordsPerPage));
    //    setDocumentToShow(tempResults.slice(0, recordsPerPage));
    //    setPanelState(new Array(documentToShow.length).fill(false));
    //    setaActivePage(1);
    //}

    //Appending the image icons for documents
    function fileIcons(fileExtension) {
        if (fileExtension === "docx" || fileExtension === "doc") {
            return "../src/assets/images/Word.jpg";
        }
        if (fileExtension === "xlsx" || fileExtension === "xls") {
            return "../src/assets/images/Excel.jpg";
        }
        if (fileExtension === "pptx" || fileExtension === "ppt") {
            return "../src/assets/images/Power_point.jpg";
        }
        if (fileExtension === "pdf") {
            return "../src/assets/images/PDF.jpg";
        }
        if (fileExtension === null || fileExtension === "aspx" || fileExtension === "txt") {
            return "../src/assets/images/Web.jpg";
        }
        if (fileExtension === "gif" || fileExtension === "png" || fileExtension === "jpg") {
            return "../src/assets/images/Image.jpg";
        }
        if (fileExtension === "zip") {
            return "../src/assets/images/Zip.jpg";
        }
        // Default icon.
        return "../src/assets/images/Share_point.jpg";
    }
    //Filtering the distinct values from array
    function getFilterTermVal(tempFunctionFilters) {
        //Filtering the unique value for term filters

        var flags = [], tempTechFun = [], l = tempFunctionFilters.length, i;
        for (i = 0; i < l; i++) {
            if (flags[tempFunctionFilters[i].label]) continue;
            flags[tempFunctionFilters[i].label] = true;
            tempTechFun.push({ id: tempFunctionFilters[i].label, label: tempFunctionFilters[i].label, checked: false });
        }
        tempTechFun.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        return tempTechFun;
    }

    //Removing the term set guid from search values
    function getTermValue(value) {
        // Expertise area is returned by search in a specific format, for example: GP0|#0{guid}|text;L0|#{guid}|text. Other formats are also possible.
        // Approach to get the correct text values:
        // Split the value on ';'
        // Per item check if it the element contains 'L0|'.
        // 		If so, split the item on '|'and use the last (2nd) element.
        var terms = value.split(';');
        var result = [];
        for (var i = 0; i < terms.length; i++) {
            if (terms[i].indexOf('L0|') >= 0) {
                result.push(terms[i].split('|')[2]);
            }
        }

        // Return the text values of the terms separated by a colon.
        return result.join('; ');
    }

    function processResponse(data: any, TechFunction: any, Discipline: any) {
        let results = {};
        data.map((o, i) => {

            if (o.Parent) {
                const categories = o.PathOfTerm.split(";");

                if (categories[0] && results[categories[0]] === undefined) {
                    results[categories[0]] = {}
                    results[categories[0]].checked = false;
                    results[categories[0]].items = []
                }

                if (categories[1] && results[categories[0]][categories[1]] === undefined) {
                    results[categories[0]][categories[1]] = {}
                    results[categories[0]][categories[1]].items = [];
                    results[categories[0]][categories[1]].checked = false;
                }

                if (categories[2] && results[categories[0]][categories[1]][categories[2]] === undefined) {
                    results[categories[0]][categories[1]][categories[2]] = {}
                    results[categories[0]][categories[1]][categories[2]].items = [];
                    results[categories[0]][categories[1]][categories[2]].checked = false;
                }

                if (categories.length && categories.length === 1) {
                    o.checked = false;
                    results[categories[0]].items.push(o);
                    results[categories[0]].ID = o.Parent;
                }

                else if (categories.length && categories.length === 2) {
                    o.checked = false;
                    results[categories[0]][categories[1]].items.push(o);
                    results[categories[0]][categories[1]].ID = o.Parent;
                }

                else if (categories.length && categories.length === 3) {
                    o.checked = false;
                    results[categories[0]][categories[1]][categories[2]].items.push(o);
                    results[categories[0]][categories[1]][categories[2]].ID = o.Parent;
                }
            }
            else {
                if (results[o.Name] === undefined) {
                    results[o.Name] = {}
                    results[o.Name].ID = o.Id;
                    results[o.Name].items = [];
                    results[o.Name].checked = false;
                }
                results[o.Name].ID = o.Id;
            }
        })

        setInitialData(JSON.parse(JSON.stringify(results)));
        let temp = [];
        Object.keys(results).forEach((o) => {
            for (let i = 0; i < TechFunction.length; i++) {
                if (o === TechFunction[i]) {
                    results[o].checked = true;
                    setShowApply(true);
                    temp.push({
                        name: o,
                        ID: results[o].ID,
                        items: results[o],
                        checked: true
                    });
                }
            }
        });
        setDisciplineData(temp);
        let tempPTE = [...temp];
        Object.keys(tempPTE).forEach((z: any) => {
            Object.keys(tempPTE[z].items).forEach((o) => {
                for (let i = 0; i < Discipline.length; i++) {
                    if (o === Discipline[i]) {
                        tempPTE[z].items[o].checked = true;
                        tempPTE.push({
                            name: o,
                            ID: tempPTE[z].items[o].ID,
                            items: tempPTE[z].items[o],
                            checked: true
                        })
                    }
                }
            });
        });
        setPTEData(tempPTE);
        setTaxonomyData(results);
        return results;
    }

    function getTermsetWithChildren(siteCollectionURL: string, termStoreName: string, termsetId: string) {
        return new Promise((resolve, reject) => {
            const taxonomy = new Session(siteCollectionURL);
            const store: any = taxonomy.termStores.getByName(termStoreName);
            store.getTermSetById(termsetId).terms.select('Name', 'Id', 'Parent').get()
                .then((data: any[]) => {
                    processResponse(data, "", "");
                    //resolve();
                }).catch(fail => {
                    console.warn(fail);
                    reject(fail);
                });
        });
    }

    function buildQueryString() {

        setShowLoading(true);

        let refinablestring00 = ''
        let refinablestring01 = ''
        let refinablestring02 = ''
        let refinablestring03 = ''
        let tfCount = 0;
        let disciplineCount = 0;
        let pteCount = 0;

        Object.keys(taxonomyData).forEach((o) => {
            if (taxonomyData[o].checked) {
                tfCount++;
                refinablestring00 = refinablestring00 + 'refinablestring11:"' + taxonomyData[o].ID + '",'
            }
        });

        disciplineData.forEach((obj: any, index: number) => {
            Object.keys(obj.items).forEach((innerKey: any, innerIndex: number) => {
                if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                    if (disciplineData[index].items[innerKey].checked) {
                        disciplineCount++;
                        const innerObj = disciplineData[index].items[innerKey];
                        refinablestring01 = refinablestring01 + 'refinablestring00:"' + innerObj.items[0].Id + '",';
                    }
                }
            })
        })


        pteData.map((obj: any, index: number) => {
            if (obj.items) {

                Object.keys(obj.items).forEach((innerKey: any, innerIndex: number) => {
                    if (['ID', 'checked', 'items'].includes(innerKey) === false) {
                        if (pteData[index].items[innerKey].checked) {
                            pteCount++;
                            const innerObj = pteData[index].items[innerKey];
                            refinablestring02 = refinablestring02 + 'refinablestring01:"' + innerObj.items[0].Id + '",';
                        }
                    }
                })

            }
        })


        //let wrapper;
        ////if (tfCount === 1 && disciplineCount === 1 && pteCount === 1) {
        ////    wrapper = "'AND (";
        ////}
        ////else 
        //if (tfCount >= 1 || disciplineCount >= 1 || pteCount >= 1) {
        //    wrapper = "'OR (";
        //}
        //else {
        //    wrapper = "'AND (";
        //}

        let finalQueryString;

        if (tfCount >= 1 && disciplineCount === 0 && pteCount === 0) {
            if (tfCount === 1) {
                finalQueryString = ("'" + refinablestring00.replace(/,\s*$/, "") + "'");
            }
            else {
                finalQueryString = "'OR (" + (refinablestring00).replace(/,\s*$/, "") + ")'";
            }
        }
        else if (tfCount >= 1 && disciplineCount >= 1 && pteCount === 0) {
            if (tfCount === 1 && disciplineCount === 1) {
                finalQueryString = "'AND (" + (refinablestring00 + refinablestring01).replace(/,\s*$/, "") + ")'";
            }
            else {
                finalQueryString = "'OR (" + (refinablestring01).replace(/,\s*$/, "") + ")'";
            }
        }
        else if (tfCount >= 1 && disciplineCount >= 1 && pteCount >= 1) {
            if (tfCount === 1 && disciplineCount === 1 && pteCount === 1) {
                finalQueryString = "'AND (" + (refinablestring00 + refinablestring01 + refinablestring02).replace(/,\s*$/, "") + ")'";
            }
            else {
                finalQueryString = "'OR (" + (refinablestring02).replace(/,\s*$/, "") + ")'";
            }
        }

        //if (tfCount === 1 && refinablestring01 === '' && refinablestring02 === '') {
        //    finalQueryString = ("'" + refinablestring00.replace(/,\s*$/, "") + "'");
        //}
        //else {
        //    finalQueryString = (wrapper + refinablestring00 + refinablestring01 + refinablestring02 + refinablestring03).replace(/,\s*$/, "") + ")'";
        //}




        finalQueryString = query.split('refinementfilters=')[0] + 'refinementfilters=' + finalQueryString;

        getSearchQuery(finalQueryString);


    }

    function hideFilters() {
        setFunctionFilter(false);
        setDisciplineFilter(false);
        setPteAreaFilter(false);
    }

    function sendToAppInsights(webpartname: string, krFilePath: string) {
        params.AppInsights.trackEvent('SPOA3.0', {
            PageUrl: krFilePath,
            PageTitle: document.title,
            EventType: 'WebPart click',
            WebPartType: 'Knowledge Repository Web Part',
            WebPartHeader: webpartname,
            UserEmail: params.userEmail
        });
        params.AppInsights.flush();
    }

    //TO DO - Use common method for select all - Functional PBI should take care of this

    // function onSelectAll(index:number){
    //     let temp:any = [...krHeaders];
    //     temp[index].filters.map( (o,i) => {
    //         o.checked = true;
    //     });
    //     setKRHeaders(temp);
    // }

};

export default Kr;