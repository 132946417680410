import React, { useState, useEffect } from 'react';
import CardWidget from '../cardWidget/CardWidget';
import helpers from '../helpers';
import { MdCreate } from "react-icons/md";
import { Button, Modal, Form } from "react-bootstrap";
import Select from 'react-select';

const LfeWidget = ({ editMode, isModerator }) => {
    const [loading, setLoading] = useState(true);
    const [isHidden, setIsHidden] = useState(false);
    const [lfeId, setLfeId] = useState('');
    const [searchLFEURL, setsearchLFEURL] = useState('');
    let lfeQuerySplitFunction = "";
    let lfeQuerySplitDiscipline = "";
    let lfeQuerySplitPTE = "";
    let lfeQuerySplitKeyword = "";
    let lfePlaylistKeyword = "";
    let lfeQuery_Keyword = "";
    let lfeQuery = '';
    let lfeFinalResults = [];
    const config = {
        title: "Learning From Experience (LFE)",
        type: "LFE",
        modalTitle: "Edit LFE Query",
        lfeSearchUrl: "https://lfe.shell.com/#/Search"
    }
    const [response, setResponse] = useState([]);

    useEffect(() => {
        getLFEConfigurationItems();
    }, []);

    return (
        <div id="lfe-widget">
            <>
                <CardWidget config={config} response={response} editMode={editMode} updateConfigurationItems={updateConfigurationItems} loading={loading} searchLFEURL={searchLFEURL} hideWebpart={hideWebpart} isModerator={isModerator} isHidden={isHidden} />
            </>
        </div>
    )

    function updateConfigurationItems(Tfunctions?: any, Tdiscipline?: any, Tpte?: any, Tsme?: any, Tkeyword?: any, TsignForums?: any) {
        setLoading(true)
        let selectedFunctionsLabel = []
        let TfunctionsLabel = '';
        let TDisciplineLabel = '';
        let TPTELabel = '';
        Object.keys(Tfunctions).forEach((o) => {
            if (Tfunctions[o].checked === true) {
                selectedFunctionsLabel.push(o);
            }
        });
        let selectedDisciplines = Tdiscipline.filter(o => o.checked);
        let selectedPTEs = Tpte.filter(o => o.checked);
        for (let j = 0; j < selectedFunctionsLabel.length; j++) {
            TfunctionsLabel = TfunctionsLabel + selectedFunctionsLabel[j];
        }
        for (let j = 0; j < selectedDisciplines.length; j++) {
            TDisciplineLabel = TDisciplineLabel + selectedDisciplines[j].label;
        }
        for (let k = 0; k < selectedPTEs.length; k++) {
            TPTELabel = TPTELabel + selectedPTEs[k].label;
        }
        let queryFinalString = '';
        if (Tkeyword !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings\"+AND (Refinablestring66:\"" + Tkeyword + "\") AND (LFELearningStatus:\"Submitted\" OR LFELearningStatus:\"Expert Approved\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=false&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=false&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created,Refinablestring66'&sortlist='Created:descending'&clienttype='ContentSearchRegular'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel !== "" && TPTELabel !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings\"+AND+owstaxIdKMPTETech:\"" + TPTELabel + "\"+OR+owstaxIdKMPrimaryTech:\"" + TfunctionsLabel + "\"+OR+owstaxIdKMSecondaryTech:\"" + TDisciplineLabel + "\" AND (LFELearningStatus:\"Submitted\" OR LFELearningStatus:\"Expert Approved\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=false&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=false&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created'&sortlist='Created:descending'";
        }
        else if (TfunctionsLabel !== "" && TDisciplineLabel !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings\"+AND+owstaxIdKMPrimaryTech:\"" + TfunctionsLabel + "\"+OR+owstaxIdKMSecondaryTech:\"" + TDisciplineLabel + "\" AND (LFELearningStatus:\"Submitted\" OR LFELearningStatus:\"Expert Approved\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=false&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=false&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created'&sortlist='Created:descending'";
        }
        else if (TfunctionsLabel !== "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings\"+AND+owstaxIdKMPrimaryTech:\"" + TfunctionsLabel + "\"+OR+owstaxIdKMSecondaryTech:\"" + TDisciplineLabel + "\" AND (LFELearningStatus:\"Submitted\" OR LFELearningStatus:\"Expert Approved\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=false&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=false&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created'&sortlist='Created:descending'";
        }
        else if (TfunctionsLabel === "" && TDisciplineLabel === "" && Tkeyword === "") {
            queryFinalString = "'path:\"https://eu001-sp.shell.com/sites/AAFAA4724/Lists/learnings\"+AND+owstaxIdKMPrimaryTech:+OR+owstaxIdKMSecondaryTech: AND (LFELearningStatus:\"Submitted\" OR LFELearningStatus:\"Expert Approved\")'&enablestemming=false&enablephonetic=false&enablenicknames=false&trimduplicates=false&enablefql=false&enablequeryrules=false&processbestbets=false&bypassresulttypes=false&processpersonalfavorites=false&generateblockranklog=false&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created'&sortlist='Created:descending'";
        }
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(lfeId).update({
            Query: queryFinalString
        }).then((item: any) => {
            console.log('Updated')
            getLFEConfigurationItems();
        }).catch(e => {
            console.log('Update failed')
        });
    }

    function getLFEConfigurationItems() {
        setResponse([]);
        helpers.getKRListView("SpoAPageData", ((items: Array<{
            Query: string
            ID: string
            IsHidden: boolean
        }>) => {
            setIsHidden(items[0].IsHidden);
            if (items.length > 0) {
                for (let i = 0; i < items.length; i++) {
                    lfeQuery = items[i].Query;
                    if (lfeQuery.indexOf('Refinablestring66') === -1) {
                        if (lfeQuery.indexOf("owstaxIdKMPrimaryTech") > -1) {
                            let lfeQueryFunction = lfeQuery.split('owstaxIdKMPrimaryTech:');
                            lfeQuerySplitFunction = "";
                            if (lfeQueryFunction.length > 1) {
                                lfeQuerySplitFunction = lfeQueryFunction[1].split('+OR')[0].trim();
                                lfeQuerySplitFunction = lfeQuerySplitFunction.replace(/^"(.*)"$/, '$1');
                            } else {
                                lfeQuerySplitFunction = "";
                            }
                        }
                        if (lfeQuery.indexOf("owstaxIdKMSecondaryTech") > -1) {
                            let lfeQueryDiscipline = lfeQuery.split('owstaxIdKMSecondaryTech:');
                            lfeQuerySplitDiscipline = "";
                            if (lfeQueryDiscipline.length > 1) {
                                lfeQuerySplitDiscipline = lfeQueryDiscipline[1].split('AND')[0].trim();
                                lfeQuerySplitDiscipline = lfeQuerySplitDiscipline.replace(/^"(.*)"$/, '$1');
                            } else {
                                lfeQuerySplitDiscipline = "";
                            }
                        }
                        if (lfeQuery.indexOf("owstaxIdKMPTETech") > -1) {
                            let lfeQueryPTE = lfeQuery.split('owstaxIdKMPTETech:');
                            lfeQuerySplitPTE = "";
                            if (lfeQueryPTE.length > 1) {
                                lfeQuerySplitPTE = lfeQueryPTE[1].split('+OR')[0].trim();
                                lfeQuerySplitPTE = lfeQuerySplitPTE.replace(/^"(.*)"$/, '$1');
                            } else {
                                lfeQuerySplitPTE = "";
                            }
                        }
                        setsearchLFEURL("https://lfe.shell.com/");
                    } else {
                        lfeQuerySplitKeyword = "";
                        lfePlaylistKeyword = "";
                        let lfeKeyword = lfeQuery.split('Refinablestring66:');
                        lfeQuery_Keyword = lfeKeyword[1].split(')')[0];
                        //To remove unwanted spacs
                        lfeQuery_Keyword = lfeQuery_Keyword.replace(/^"(.*)"$/, '$1');
                        //To remove unwanted special characters at last
                        lfeQuery_Keyword = lfeQuery_Keyword.replace(/[,;]$/, "");
                        let splitLFEQuerykeyword = lfeQuery_Keyword.split(';');
                        //or(or(RefinableString66:"MFD",RefinableString66:"FEED"),or(RefinableString66:"projectreview",RefinableString66:"ROV"))
                        if (splitLFEQuerykeyword.length == 1) {
                            lfeQuerySplitKeyword = "RefinableString66:\"" + splitLFEQuerykeyword[0] + "\"";
                            lfePlaylistKeyword = "LFEPlaylistTags:\"" + splitLFEQuerykeyword[0] + "\"";
                        }
                        if (splitLFEQuerykeyword.length == 2) {
                            lfePlaylistKeyword = "(LFEPlaylistTags:\"" + splitLFEQuerykeyword[0] + "\" OR LFEPlaylistTags:\"" + splitLFEQuerykeyword[1] + "\")";
                            lfeQuerySplitKeyword = "or(RefinableString66:\"" + splitLFEQuerykeyword[0] + "\", RefinableString66:\"" + splitLFEQuerykeyword[1] + "\")";
                        }
                        if (splitLFEQuerykeyword.length == 3) {
                            lfePlaylistKeyword = "((LFEPlaylistTags:\"" + splitLFEQuerykeyword[0] + "\" OR LFEPlaylistTags:\"" + splitLFEQuerykeyword[1] + "\") OR (LFEPlaylistTags:\"" + splitLFEQuerykeyword[2] + "\"))";
                            lfeQuerySplitKeyword = "or(or(RefinableString66:\"" + splitLFEQuerykeyword[0] + "\", RefinableString66:\"" + splitLFEQuerykeyword[1] + "\"),RefinableString66:\"" + splitLFEQuerykeyword[2] + "\")";
                        }
                        if (splitLFEQuerykeyword.length == 4) {
                            lfePlaylistKeyword = "((LFEPlaylistTags:\"" + splitLFEQuerykeyword[0] + "\" OR LFEPlaylistTags:\"" + splitLFEQuerykeyword[1] + "\") OR (LFEPlaylistTags:\"" + splitLFEQuerykeyword[2] + "\" OR LFEPlaylistTags:\"" + splitLFEQuerykeyword[3] + "\"))";
                            lfeQuerySplitKeyword = "or(or(RefinableString66:\"" + splitLFEQuerykeyword[0] + "\", RefinableString66:\"" + splitLFEQuerykeyword[1] + "\"),or(RefinableString66:\"" + splitLFEQuerykeyword[2] + "\", RefinableString66:\"" + splitLFEQuerykeyword[3] + "\"))";
                        }
                        setsearchLFEURL("https://lfe.shell.com/");
                    }
                    setLfeId(items[i].ID);
                    var searchUrl = "";
                    if (lfeQuerySplitKeyword != "") {
                        searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")+AND+(LFEItemStatus:\"Active\")'&rowlimit=3&selectproperties='Title,owstaxIdKMSecondaryTech,owstaxIdKMPrimaryTech,LFELearningID,LFELearningStatus,LFELearningType,LFELearningSourceType,Created,Refinablestring66'&refinementfilters='" + lfeQuerySplitKeyword + "'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                    } else {
                        searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+LFEItemStatus:\"Active\"+AND+(owstaxIdKMSecondaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"+OR+owstaxIdKMPrimaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\")+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")'&rowlimit=3&selectproperties='Title%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech%2cLFELearningID%2cLFELearningStatus%2cLFELearningType%2cLFELearningSourceType%2cCreated'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                    }
                    var executor = new SP.RequestExecutor(helpers.appweburl);
                    executor.executeAsync(
                        {
                            url: searchUrl,
                            method: "GET",
                            headers: { "Accept": "application/json; odata=verbose" },
                            success: onGetLFESuccess,
                            error: onError
                        }
                    );
                }

            } else {
                setResponse([])
                setLoading(false)
            }
        }), "ID,Title,Query,Link,Section,Application,IsHidden", "Application eq 'LFE3.0'");
    }

    function onGetLFESuccess(data) {
        let items = [];
        let submittedDate = '';
        let lfeTitle = '';
        let formattedValidDate = '';
        let lfeStatus = '';
        let lfePath = '';
        let lfeSourceType = '';
        let lfeLearnType = '';
        let lfeExpArea = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    submittedDate = '';
                    lfeTitle = '';
                    formattedValidDate = '';
                    lfeStatus = '';
                    lfePath = '';
                    lfeSourceType = '';
                    lfeLearnType = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "Created") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                submittedDate = jsonresults[i].Cells.results[j].Value;
                                if (submittedDate) {
                                    let validDate = new Date(submittedDate);
                                    let validDay = validDate.getUTCDate();
                                    let validMonth = validDate.getUTCMonth();
                                    let validYear = validDate.getUTCFullYear();
                                    formattedValidDate = validDay + " " + monthNames[validMonth] + " " + validYear;
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Title") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfeTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningID") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfePath = "https://lfe.shell.com/#/ViewLearning/" + jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningStatus") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfeStatus = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningSourceType") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfeSourceType = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningType") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfeLearnType = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                    }

                    if (lfeQuerySplitFunction != "" && lfeQuerySplitDiscipline != "" && lfeQuerySplitPTE != "") {
                        lfeExpArea = "Function: " + lfeQuerySplitFunction + ", Discipline: " + lfeQuerySplitDiscipline + " and PTE: " + lfeQuerySplitPTE;
                    }
                    else if (lfeQuerySplitFunction != "" && lfeQuerySplitDiscipline != "" && lfeQuerySplitPTE == "") {
                        lfeExpArea = "Function: " + lfeQuerySplitFunction + " and Discipline: " + lfeQuerySplitDiscipline;
                    }
                    else if (lfeQuerySplitFunction != "" && lfeQuerySplitDiscipline == "" && lfeQuerySplitPTE == "") {
                        lfeExpArea = "Function: " + lfeQuerySplitFunction;
                    }
                    else if (lfeQuery_Keyword != "") {
                        lfeExpArea = "Keyword: " + lfeQuery_Keyword;
                    }
                    items.push({
                        lfeTitle: lfeTitle,
                        lfeStatus: lfeStatus,
                        submittedDate: formattedValidDate,
                        lfePath: lfePath,
                        lfeSourceType: lfeSourceType,
                        lfeLearnType: lfeLearnType,
                        lfeExpArea: lfeExpArea,
                    });
                }
                lfeFinalResults.push({
                    lferesult: items
                });
                var searchUrl = "";
                if (lfeQuerySplitKeyword != "") {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")+AND+(LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&refinementfilters='" + lfeQuerySplitKeyword + "'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                } else {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")+AND+(owstaxIdKMSecondaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"+OR+owstaxIdKMPrimaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\")+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                }
                var executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync(
                    {
                        url: searchUrl,
                        method: "GET",
                        headers: { "Accept": "application/json; odata=verbose" },
                        success: onGetLFISuccess,
                        error: onError
                    }
                );

            }
            else {
                lfeFinalResults.push({
                    lferesult: items
                });
                var searchUrl = "";
                if (lfeQuerySplitKeyword != "") {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")+AND+(LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&refinementfilters='" + lfeQuerySplitKeyword + "'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                } else {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")+AND+(owstaxIdKMSecondaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"+OR+owstaxIdKMPrimaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\")+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
                }
                var executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync(
                    {
                        url: searchUrl,
                        method: "GET",
                        headers: { "Accept": "application/json; odata=verbose" },
                        success: onGetLFISuccess,
                        error: onError
                    }
                );
            }
        } else {
            lfeFinalResults.push({
                lferesult: items
            });
            var searchUrl = "";
            if (lfeQuerySplitKeyword != "") {
                searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")+AND+(LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&refinementfilters='" + lfeQuerySplitKeyword + "'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
            } else {
                searchUrl = helpers.appweburl + "/_api/search/query?querytext='(path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2flearnings\"+AND+LFEItemStatus:\"Active\"+AND+Refinablestring32:\"Learning+from+Incidents+(LFI)+-+HSSE\")+AND+(owstaxIdKMSecondaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"+OR+owstaxIdKMPrimaryTech:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\")+AND+(LFELearningStatus:\"Submitted\"+OR+LFELearningStatus:\"Expert+Approved\")'&rowlimit=3&selectproperties='LFELearningID%2cTitle%2cLFEApprovedBy%2cLFELearningApproved%2cTitle%2cLFELearningSourceType%2cowstaxIdKMSecondaryTech%2cowstaxIdKMPrimaryTech'&sortlist='created:descending'&clienttype='ContentSearchRegular'";
            }
            var executor = new SP.RequestExecutor(helpers.appweburl);
            executor.executeAsync(
                {
                    url: searchUrl,
                    method: "GET",
                    headers: { "Accept": "application/json; odata=verbose" },
                    success: onGetLFISuccess,
                    error: onError
                }
            );
        }
    }

    function onGetLFISuccess(data) {
        let itemsLFI = [];
        let submittedDate = '';
        let lfiTitle = '';
        let formattedValidDate = '';
        let lfiApprovedBy = '';
        let lfiPath = '';
        let lfiSourceType = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            console.log("LFI results: " + jsonresults);
            const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    submittedDate = '';
                    lfiTitle = '';
                    formattedValidDate = '';
                    lfiApprovedBy = '';
                    lfiPath = '';
                    lfiSourceType = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningApproved") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                submittedDate = jsonresults[i].Cells.results[j].Value;
                                if (submittedDate) {
                                    let validDate = new Date(submittedDate);
                                    let validDay = validDate.getUTCDate();
                                    let validMonth = validDate.getUTCMonth();
                                    let validYear = validDate.getUTCFullYear();
                                    formattedValidDate = validDay + " " + monthNames[validMonth] + " " + validYear;
                                } else {
                                    submittedDate = "";
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Title") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfiTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningID") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfiPath = "https://lfe.shell.com/#/ViewLearning/" + jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFEApprovedBy") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfiApprovedBy = jsonresults[i].Cells.results[j].Value;
                                if (lfiApprovedBy !== null) {
                                    lfiApprovedBy = lfiApprovedBy.split(';')[0];
                                } else {
                                    lfiApprovedBy = "";
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFELearningSourceType") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                lfiSourceType = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                    }
                    itemsLFI.push({
                        lfiTitle: lfiTitle,
                        lfiApprovedBy: lfiApprovedBy,
                        submittedDate: formattedValidDate,
                        lfiPath: lfiPath,
                        lfiSourceType: lfiSourceType,
                    });
                }
                lfeFinalResults.push({
                    lfiresult: itemsLFI
                });
                var searchUrl = "";
                if (lfePlaylistKeyword !== "") {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+" + lfePlaylistKeyword + "'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                } else {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+LFEPLFunction:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                }
                var executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync(
                    {
                        url: searchUrl,
                        method: "GET",
                        headers: { "Accept": "application/json; odata=verbose" },
                        success: onGetPlaylistSuccess,
                        error: onError
                    }
                );
            }
            else {
                lfeFinalResults.push({
                    lfiresult: itemsLFI
                });
                var searchUrl = "";
                if (lfePlaylistKeyword !== "") {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+" + lfePlaylistKeyword + "'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                } else {
                    searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+LFEPLFunction:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
                }
                var executor = new SP.RequestExecutor(helpers.appweburl);
                executor.executeAsync(
                    {
                        url: searchUrl,
                        method: "GET",
                        headers: { "Accept": "application/json; odata=verbose" },
                        success: onGetPlaylistSuccess,
                        error: onError
                    }
                );
            }
        } else {
            lfeFinalResults.push({
                lfiresult: itemsLFI
            });
            var searchUrl = "";
            if (lfePlaylistKeyword !== "") {
                searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+" + lfePlaylistKeyword + "'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
            } else {
                searchUrl = helpers.appweburl + "/_api/search/query?querytext='path:\"https:%2f%2feu001-sp.shell.com%2fsites%2fAAFAA4724%2fLists%2fKMPlaylist\"+AND+LFEItemStatus:\"Active\"+AND+LFEPLFunction:\"" + lfeQuerySplitFunction + ":" + lfeQuerySplitDiscipline + ":" + lfeQuerySplitPTE + "\"'&rowlimit=3&selectproperties='Title%2cLFEPlaylistId%2cLFEPLFunction%2cModifiedOWSDATE'&sortlist='write:descending'&clienttype='ContentSearchRegular'";
            }
            var executor = new SP.RequestExecutor(helpers.appweburl);
            executor.executeAsync(
                {
                    url: searchUrl,
                    method: "GET",
                    headers: { "Accept": "application/json; odata=verbose" },
                    success: onGetPlaylistSuccess,
                    error: onError
                }
            );
        }

    }

    function onGetPlaylistSuccess(data) {
        let items = [];
        let submittedDate = '';
        let playlistTitle = '';
        let formattedValidDate = '';
        let playlistPath = '';
        let jsonObject = JSON.parse(data.body);
        if (jsonObject.d.query.PrimaryQueryResult !== null) {
            let jsonresults = jsonObject.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results;
            console.log("Playlist" + jsonresults);
            const monthNames = ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            if (jsonresults !== null && jsonresults.length > 0) {
                for (let i = 0; i < jsonresults.length; i++) {
                    submittedDate = '';
                    playlistTitle = '';
                    formattedValidDate = '';
                    playlistPath = '';
                    for (var j = 0; j < jsonresults[i].Cells.results.length; j++) {
                        if (jsonresults[i].Cells.results[j].Key === "ModifiedOWSDATE") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                submittedDate = jsonresults[i].Cells.results[j].Value;
                                if (submittedDate) {
                                    let validDate = new Date(submittedDate);
                                    let validDay = validDate.getUTCDate();
                                    let validMonth = validDate.getUTCMonth();
                                    let validYear = validDate.getUTCFullYear();
                                    formattedValidDate = validDay + " " + monthNames[validMonth] + " " + validYear;
                                }
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "Title") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                playlistTitle = jsonresults[i].Cells.results[j].Value;
                            }
                        }
                        if (jsonresults[i].Cells.results[j].Key === "LFEPlaylistId") {
                            if (jsonresults[i].Cells.results[j].Value !== "") {
                                playlistPath = "https://lfe.shell.com/#/ViewPlaylist/" + jsonresults[i].Cells.results[j].Value;
                            }
                        }
                    }
                    items.push({
                        playlistTitle: playlistTitle,
                        playListsubmittedDate: formattedValidDate,
                        playlistPath: playlistPath,
                    });

                }
                lfeFinalResults.push({
                    playlistResult: items
                });
                setResponse(lfeFinalResults);
                setLoading(false);
            }
            else {
                lfeFinalResults.push({
                    playlistResult: items
                });
                setResponse(lfeFinalResults);
                setLoading(false);
            }
        } else {
            lfeFinalResults.push({
                playlistResult: items
            });
            setResponse(lfeFinalResults);
            setLoading(false)
        }

    }
    function onError(e) {
        console.error("Error fetching LFE", e)
    }

    async function hideWebpart(toHide: boolean) {
        setLoading(true);
        window['SP_Propertyweb'].lists.getByTitle('SpoAPageData').items.getById(parseInt(lfeId, 10)).update({ IsHidden: toHide }).then(i => {
            setIsHidden(toHide);
            setLoading(false);
        }).catch(e => {
            console.log("error updating CustomWebpart!", e)
        });
    }


};

export default LfeWidget;